import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import SEO from 'components/shared/SEO';
import Header from 'components/Headers/Header';
import PromotedVideo from 'components/Contents/PromotedVideo';
import VideosSection from 'components/Contents/VideosSection';
import InvestmentsSection from 'components/Contents/InvestmentsSection';
// import Section from 'components/shared/Section';
// import Content from 'components/shared/Content';
// import Player from 'components/Player/Player';
import HorizontalCarousel from 'components/Contents/HorizontalCarousel';
import Footer from 'components/Footer/Footer';

const IndexPage = ({ data }) => {
  const PAGE = data.wpgraphql.page.PageHome;
  const PAGE_SEO = data.wpgraphql.page.seo;

  let PROMOTED_VIDEO = {
    number: PAGE.promotedVideoVideo.promotedVideoVideoNumber,
    title: PAGE.promotedVideoVideo.promotedVideoVideoTitle,
    url: PAGE.promotedVideoVideo.promotedVideoVideoUrl,
    duration: PAGE.promotedVideoVideo.promotedVideoVideoDuration,
    thumbnail:
      PAGE.promotedVideoVideo.promotedVideoVideoThumbnail &&
      PAGE.promotedVideoVideo.promotedVideoVideoThumbnail.imageFile
        .childImageSharp.fluid,
  };

  const VIDEOS = data.wpgraphql.posts.nodes
    .map(
      ({
        slug,
        Post: {
          videoTitle,
          videoDescription,
          videoNumber,
          videoPremierDate,
          videoPromoted,
          videoDuration,
          videoUrl,
          videoThumbnail,
          videoTrailer,
        },
      }) => ({
        number: videoNumber,
        title: videoTitle,
        url: videoUrl,
        excerpt: videoDescription,
        thumbnail: videoThumbnail.imageFile.childImageSharp.fluid,
        duration: videoDuration,
        premierDate: new Date(
          `${videoPremierDate.split(' ')[0].split('/').reverse().join('/')} ${
            videoPremierDate.split(' ')[1]
          } ${videoPremierDate.split(' ')[2]}`
        ),
        promoted: videoPromoted,
        trailer: videoTrailer,
        slug,
      })
    )
    .filter(({ slug }) => slug.includes('en_'))
    .sort((a, b) => a.number - b.number);

  const INVESTMENTS = PAGE.allInvestmentsList.map(
    ({
      investmentTitle,
      investmentVideoUrl,
      investmentThumbnail,
      investmentVideoDuration,
    }) => ({
      title: investmentTitle,
      url: investmentVideoUrl,

      thumbnail: investmentThumbnail.imageFile.childImageSharp.fluid,
      duration: investmentVideoDuration,
    })
  );

  PROMOTED_VIDEO = PROMOTED_VIDEO.url ? PROMOTED_VIDEO : VIDEOS[0];

  return (
    <>
      <SEO
        title={PAGE_SEO.title}
        description={PAGE_SEO.metaDesc}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDesc={PAGE_SEO.opengraphDescription}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDesc={PAGE_SEO.twitterDescription}
        image={PAGE_SEO.opengraphImage && PAGE_SEO.opengraphImage.sourceUrl}
      />
      <Header
        images={PAGE.bannerImages.map(({ bannerImagesImg }) => ({
          src: bannerImagesImg.imageFile.childImageSharp.fluid,
          alt: 'hero image',
        }))}
        title={PAGE.bannerTitle}
        text={PAGE.bannerText}
      />
      <main>
        {/* {PROMOTED_VIDEO && (
          <PromotedVideo
            heading={PAGE.promotedVideoHeadingOne}
            title={PAGE.promotedVideoHeading}
            titleURL={PAGE.promotedVideoVideo.promotedVideoVideoTitleUrl}
            video={PROMOTED_VIDEO}
          />
        )} */}
        {VIDEOS.length !== 0 && (
          <VideosSection
            homepage
            title={PAGE.allVideosHeading}
            videos={VIDEOS}
          />
        )}
        {INVESTMENTS.length !== 0 && (
          <InvestmentsSection
            homepage
            title={PAGE.allInvestmentsHeading}
            investments={INVESTMENTS}
          />
        )}
        <HorizontalCarousel
          title={PAGE.awardsHeading}
          items={
            PAGE.awardsItems &&
            PAGE.awardsItems.map(({ awardsItemsLink, awardsItemsImg }) => ({
              img: awardsItemsImg.imageFile.childImageSharp.fixed,
              link: awardsItemsLink,
            }))
          }
          shortMobile
        />
        {/* <Section noTop noBottom>
          <Content>
            <Player
              video={{
                url: PAGE.videoUrl,
                thumbnail: PAGE.videoThumbnail.imageFile.childImageSharp.fluid,
              }}
            />
          </Content>
        </Section> */}
        <HorizontalCarousel
          title={PAGE.creatorsMediaHeading}
          items={
            PAGE.creatorsMediaItems &&
            PAGE.creatorsMediaItems.map(
              ({ creatorsMediaItemsLink, creatorsMediaItemsImg }) => ({
                img: creatorsMediaItemsImg.imageFile.childImageSharp.fixed,
                link: creatorsMediaItemsLink,
              })
            )
          }
        />
      </main>
      <Footer
        elements={PAGE.footerPros.map(
          ({ footerProsText, footerProsHighlight, footerProsIcon }) => ({
            highlight: footerProsHighlight,
            text: footerProsText,
            icon: footerProsIcon,
          })
        )}
      />
    </>
  );
};

IndexPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export const query = graphql`
  query queryHomePageEN {
    wpgraphql {
      page(id: "/homepage/", idType: URI) {
        seo {
          title
          twitterTitle
          opengraphTitle
          metaDesc
          opengraphDescription
          twitterDescription
          opengraphImage {
            sourceUrl
          }
        }
        PageHome {
          bannerTitle
          bannerText
          bannerImages {
            bannerImagesImg {
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          videoUrl
          videoThumbnail {
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          promotedVideoHeadingOne
          promotedVideoHeading
          promotedVideoVideo {
            promotedVideoVideoTitle
            promotedVideoVideoTitleUrl
            promotedVideoVideoUrl
            promotedVideoVideoDuration
            promotedVideoVideoNumber
            promotedVideoVideoThumbnail {
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          allVideosHeading
          allInvestmentsHeading
          allInvestmentsList {
            investmentTitle
            investmentThumbnail {
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            investmentVideoDuration
            investmentVideoUrl
          }
          awardsHeading
          awardsItems {
            awardsItemsLink
            awardsItemsImg {
              sourceUrl
              imageFile {
                childImageSharp {
                  fixed {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
          creatorsMediaHeading
          creatorsMediaItems {
            creatorsMediaItemsLink
            creatorsMediaItemsImg {
              sourceUrl
              imageFile {
                childImageSharp {
                  fixed {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
          footerPros {
            footerProsText
            footerProsHighlight
            footerProsIcon
          }
        }
      }
      posts(first: 100) {
        nodes {
          slug
          Post {
            videoTitle
            videoDescription
            videoNumber
            videoPremierDate
            videoPromoted
            videoDuration
            videoUrl
            videoTrailer
            videoThumbnail {
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
